import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  grid: FelaCSS;
  codeWrapper: FelaCSS;
}

export const componentStyles: IStyles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '88px auto',
    columnGap: spacing[6],
    paddingTop: spacing[3],
  },

  codeWrapper: {
    width: 88,
    height: 88,
    padding: '3px',
    overflow: 'hidden',
    border: `1px ${colors.space15} solid`,
    borderRadius: 8,
  },
};
