import { ComponentProps } from 'react';
import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { A, Box, FelaCSS, ImageX } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { getBadgesInfo } from 'app-shared/lib/app/badges-info';
import { componentStyles } from './app-badge.style';

interface IProps {
  market: Market;
  start?: boolean;
  variant?: 'onlyBadgesHorizontal' | 'withQrCode';
  onClick?: ComponentProps<typeof A>['onClick'];
  wrapperStyle?: FelaCSS;
}

const AppBadge = ({
  market,
  start,
  variant = 'onlyBadgesHorizontal',
  onClick,
  wrapperStyle = {},
}: IProps) => {
  const { apple, google, code } = getBadgesInfo(market);
  if (variant === 'onlyBadgesHorizontal') {
    return (
      <Box w="100%" style={wrapperStyle}>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent={start ? 'flex-start' : 'center'}>
          <Box>
            <A href={apple.url} target="_blank" onClick={onClick}>
              <Box w={120}>
                <ImageX src={apple.img} w={150} h={50} fit="scale" />
              </Box>
            </A>
          </Box>
          <A href={google.url} target="_blank" onClick={onClick}>
            <Box w={120}>
              <ImageX src={google.img} w={150} h={64} fit="scale" />
            </Box>
          </A>
        </Box>
      </Box>
    );
  }

  if (variant === 'withQrCode') {
    return (
      <Box style={mergeStyles([componentStyles.grid, wrapperStyle])}>
        <Box style={componentStyles.codeWrapper}>
          <ImageX src={code.img} w={80} h={80} fit="scale" />
        </Box>
        <Box flexDirection="column">
          <Box pb={2}>
            <A href={apple.url} target="_blank" onClick={onClick}>
              <Box w={120}>
                <ImageX src={apple.img} w={150} h={50} fit="scale" />
              </Box>
            </A>
          </Box>
          <A href={google.url} target="_blank" onClick={onClick}>
            <Box w={120}>
              <ImageX src={google.img} w={150} h={58} fit="scale" />
            </Box>
          </A>
        </Box>
      </Box>
    );
  }

  return null;
};

export default AppBadge;
