import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';
import type { Market } from '@bridebook/toolbox/src/gazetteer';
import { Box } from '@bridebook/ui';
import AppBadge from 'app-shared/components/app-badge/app-badge';
import componentStyles from './footer-app-badges.style';

interface IProps {
  market: Market;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

const FooterAppBadges = ({ market, onClick }: IProps) => {
  const { t } = useTranslation('common');
  const styles = componentStyles();

  return (
    <Box style={styles.wrapper}>
      {t('footer.getApp.subheading', 'Plan together: anytime, anywhere')}
      <AppBadge market={market} variant="withQrCode" onClick={onClick} />
    </Box>
  );
};

export default FooterAppBadges;
