import getSpacingValue from '../../..//fela-utils/get-spacing-value';
import mergeStyles from '../../../fela-utils/merge-styles';
import responsiveStylePropQueries from '../../../fela-utils/responsive-style-prop-queries';
import { IColSpacing, IStylingProps } from '../../../themes/types';
import { FelaCSSWithCustomSelectors } from '../../fela/flowtypes';

interface IStyleProps extends IColSpacing {
  gap?: IStylingProps['gap'];
}

interface IStyles {
  wrap: FelaCSSWithCustomSelectors;
}

const styles = ({
  gap = 0,
  xs,
  sm,
  md,
  lg,
  xsStart,
  smStart,
  mdStart,
  lgStart,
}: IStyleProps): IStyles => {
  const wrapStyles: FelaCSSWithCustomSelectors = {
    flexShrink: 0,
    gridColumnStart: `${xsStart}`,
    gridColumnEnd: `span ${xs}`,
    minHeight: 0,
    minWidth: 0,
    ...getSpacingValue('gap', gap),

    maxWidthMobile: {
      gridColumnStart: `${xsStart}`,
      gridColumnEnd: `span ${xs}`,
    },

    maxWidthTabletSm: {
      minWidthTablet: {
        gridColumnStart: `${smStart}`,
        gridColumnEnd: `span ${sm}`,
      },
    },

    maxWidthTablet: {
      minWidthTabletLg: {
        gridColumnStart: `${mdStart}`,
        gridColumnEnd: `span ${md}`,
      },
    },

    minWidthDesktop: {
      gridColumnStart: `${lgStart}`,
      gridColumnEnd: `span ${lg}`,
    },
  };

  return {
    wrap: mergeStyles([
      wrapStyles,
      responsiveStylePropQueries('gridColumnStart', [xsStart, smStart, mdStart, lgStart]),
      responsiveStylePropQueries('gridColumnEnd', [
        `span ${xs}`,
        `span ${sm}`,
        `span ${md}`,
        `span ${lg}`,
      ]),
    ]),
  };
};

export default styles;
