import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  copyrightWrap: FelaCSS;
  urlsWrap: FelaCSS;
  socialMediaContainer: FelaCSS;
  localeSelect: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    supportsSafeAreaInset: {
      marginBottom: 'env(safe-area-inset-bottom)',
    },

    maxWidthTabletSm: {
      flexDirection: 'column',
    },
  },

  copyrightWrap: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontDefault: 11,
    lineHeight: '1em',
    color: colors.space40,
  },

  urlsWrap: {
    flexDirection: 'row',
    alignItems: 'center',

    maxWidthTabletSm: {
      flexDirection: 'column',
    },
  },

  socialMediaContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  localeSelect: {
    marginLeft: 5,
    minWidth: 250,

    maxWidthTabletSm: {
      width: '100%',
      marginLeft: 0,
      marginTop: 5,
    },
  },
});

export default styles;
