import { TSpacingValue } from '../../../themes/types';
import { spacing } from '../../../themes/variables';
import { FelaCSS } from '../../fela/flowtypes';

interface IStyleProps {
  noPadding?: boolean;
  overridePadding?: TSpacingValue;
  fullWidth?: boolean;
}

interface IStyles {
  wrap: FelaCSS;
}

export const CONTAINER_SIZE_LG = '1260px';

const styles = ({ noPadding, overridePadding, fullWidth }: IStyleProps): IStyles => ({
  wrap: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: noPadding ? '0' : spacing[overridePadding] ?? '10px',
    paddingRight: noPadding ? '0' : spacing[overridePadding] ?? '10px',
    width: fullWidth ? '100%' : CONTAINER_SIZE_LG,
    maxWidth: '100%',
    flexShrink: 0,

    maxWidthMobile: {
      width: '100%',
    },

    maxWidthTabletSm: {
      minWidthTablet: {
        width: fullWidth ? '100%' : '740px',
      },
    },

    maxWidthTablet: {
      minWidthTabletLg: {
        width: fullWidth ? '100%' : '960px',
      },
    },
  },
});
export default styles;
