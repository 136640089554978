import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    fontDefault: 15,
    color: colors.space,
  },
});

export default styles;
