// from https://github.com/ytase/react-thumbor-img/blob/master/module/urlgenerator.js
/* eslint-disable */

interface CropSectionTypes {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

function cropSection(c: CropSectionTypes) {
  return `${c.left}x${c.top}:${c.right}x${c.bottom}`;
}

function filtersURIComponent(filters: Array<string | boolean>) {
  const elements = ['filters'];
  for (let name in filters) {
    if (filters.hasOwnProperty(name)) {
      const parameters = filters[name];
      let stringParameters;
      // If we have several parameters, they were passed as an array
      // and now they need to be comma separated, otherwise there is just one to convert to a string
      if (Array.isArray(parameters)) {
        stringParameters = parameters.join(',');
      }
      // If true, we don't even need to do anything, we just have an empty string and insert ()
      // Ex: {grayscale: true} => grayscale()
      else if (parameters === true) {
        stringParameters = '';
      } else {
        stringParameters = String(parameters);
      }
      elements.push(`${name}(${stringParameters})`);
    }
  }
  return elements.join(':');
}

interface thumborOptions {
  server?: string;
  width?: number;
  height?: number;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  trim?: boolean;
  fitIn?: boolean;
  horizontalAlign?: string;
  verticalAlign?: string;
  smart?: boolean;
  filters?: Array<string>;
  manualCrop?: CropSectionTypes;
}

export const THUMBOR_BASE_URL = 'https://img.bridebook.co.uk';

function thumborURL(
  src: string,
  {
    server = THUMBOR_BASE_URL,
    width,
    height,
    flipHorizontal = false,
    flipVertical = false,
    trim = false,
    fitIn = false,
    horizontalAlign = 'center',
    verticalAlign = 'middle',
    smart,
    filters = [],
    manualCrop,
  }: thumborOptions = {},
) {
  const urlComponents = [server];

  // Add the trim parameter after unsafe if appliable
  trim && urlComponents.push('trim');

  // Add the crop parameter if any
  manualCrop && urlComponents.push(cropSection(manualCrop));

  // Add the fit-in parameter after crop if appliable
  fitIn && urlComponents.push('fit-in');

  if (width && height) {
    const thumborWidth = flipHorizontal ? -width : width;
    const thumborHeight = flipVertical ? -height : height;
    const finalSize = `${thumborWidth}x${thumborHeight}`;
    urlComponents.push(finalSize);
  }

  // Adds the horizontal alignement after the size
  urlComponents.push(horizontalAlign);

  // Adds the vertical alignement after the size
  urlComponents.push(verticalAlign);

  // Adds the smart parameter if appliable
  smart && urlComponents.push('smart');

  // Compile the filters and add them right before the URI
  Object.keys(filters).length > 0 && urlComponents.push(filtersURIComponent(filters));

  // Finally, adds the real image uri
  urlComponents.push(src);

  const url = urlComponents.join('/');
  return url;
}

export { thumborURL };
