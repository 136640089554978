import React, { SyntheticEvent } from 'react';
import { useInView } from 'react-intersection-observer';
import { getWatermarkParams } from '@bridebook/toolbox/src/getWatermarkParams';
import type { Slug } from '@bridebook/toolbox/src/types';
import { IStylingProps } from '../../../themes/types';
import { getProportionedImagePadding } from '../../../utils/get-propotioned-image-padding';
import useNativeLazyLoading from '../../../utils/hooks/use-native-lazy-loading';
import { FelaCSS } from '../../fela/flowtypes';
import { processImage } from '../utils/support';
import { thumborURL } from '../utils/thumbor-urlgenerator';
import componentStyles from './imagex.style';

const entropyCategories: Slug[] = [
  'jewellery',
  'venue',
  'florist',
  'cakes',
  'stationery',
  'transport',
  'decoration',
  'marquee',
];

export interface SharedTypes {
  style?: FelaCSS;
}

type ILoadingType = 'eager' | 'auto' | 'lazy';

interface PropTypes extends SharedTypes, IStylingProps {
  allowPin?: boolean;
  w: number;
  h: number;
  altText?: string;
  imgTitle?: string;
  customParams?: object;
  fit?: string;
  portrait?: boolean;
  crop?: string;
  slug?: Slug;
  src: string;
  isMobile?: boolean;
  isTablet?: boolean;
  wm?: boolean;
  onLoad?: (event: SyntheticEvent<HTMLImageElement>) => void;
  onError?: (event: SyntheticEvent<HTMLImageElement>) => void;
  thumbor?: boolean;
  isVenue?: boolean;
  // Define specific format of a file, like "png"
  format?: string;
  loading?: ILoadingType;
  imageStyle?: FelaCSS;
  // Do not add any additional params to the original src
  forceCleanSrc?: boolean;
}

const thumborDefaultProps = {
  server: 'https://img.bridebook.co.uk',
  filters: [],
  flipHorizontal: false,
  flipVertical: false,
  trim: false,
  fitIn: false,
  horizontalAlign: 'center',
  verticalAlign: 'middle',
  smart: true,
  generateSrcSet: false,
};

const ImageX = ({
  allowPin,
  w: imgW,
  h: imgH,
  altText,
  imgTitle,
  customParams = {},
  fit = 'crop',
  portrait,
  crop,
  slug = 'photo',
  src: initSrc,
  isTablet,
  wm,
  onLoad,
  style,
  onError,
  thumbor,
  format,
  isVenue = false,
  loading = 'eager',
  imageStyle,
  forceCleanSrc = false,
  ...restProps
}: PropTypes) => {
  const supportsNativeLazyLoading = useNativeLazyLoading();

  const [ref, inView] = useInView({
    // Important not to hide already loaded images!
    triggerOnce: true,
    // Start loading an image earlier
    rootMargin: '200px',
    initialInView: loading == 'eager',
    skip: loading == 'eager',
  });

  // FIXME: Temporary workaround before paths in FS are updated, see LIVE-13127
  const src = initSrc && initSrc.replace('/suppliers/', '/weddingsuppliers/');
  let imgCrop = 'entropy';
  const entropyCategory = entropyCategories.includes(slug);
  if (crop) imgCrop = crop;
  imgCrop = entropyCategory ? 'entropy' : 'faces';
  let imgFit = 'crop';
  if (fit) imgFit = fit;
  customParams = getWatermarkParams({
    imgH,
    imgW,
    wm,
    src,
    h: imgH,
    portrait,
  });

  /**
   * Don't specify `fm` as an option here.
   * The `auto=format` from processImage will pick the best option.
   */
  const srcOptions = {
    ...customParams,
    dpr: typeof window === 'undefined' || window.devicePixelRatio > 1 ? 2 : 1,
    crop: imgCrop,
    fit: imgFit,
    w: imgW,
    h: imgH,
  };

  const imgSrc = forceCleanSrc
    ? src
    : thumbor
    ? thumborURL(src, {
        height: imgH,
        width: imgW,
        ...thumborDefaultProps,
      })
    : processImage(src, srcOptions, isVenue);

  const paddingBottom = getProportionedImagePadding({ width: imgW, height: imgH });

  const imgLoadProps = handleImgLoading(loading, {
    src: imgSrc,
    inView,
    supportsNativeLazyLoading,
  });

  const styles = componentStyles({ paddingBottom, style, imageStyle });

  return (
    <div style={styles.wrapper} ref={ref} {...restProps}>
      <img
        style={styles.image}
        className="img-el"
        data-pin-nopin={!allowPin}
        alt={altText}
        title={imgTitle}
        onLoad={onLoad}
        draggable={false}
        onError={onError}
        {...imgLoadProps}
      />
    </div>
  );
};

const handleImgLoading = (
  loading: ILoadingType,
  params: {
    src: string;
    inView: boolean;
    supportsNativeLazyLoading: boolean | undefined;
  },
): Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'loading' | 'src'> => {
  switch (loading) {
    case 'auto':
      if (params.supportsNativeLazyLoading) {
        return {
          loading: params.inView ? 'eager' : 'lazy',
          src: params.src,
        };
      } else {
        return {
          // if it's no js page return src
          src: params.inView ? params.src : '',
        };
      }

    case 'lazy':
      if (params.supportsNativeLazyLoading) {
        return {
          loading: 'lazy',
          src: params.src,
        };
      } else {
        return {
          src: params.inView ? params.src : '',
        };
      }

    case 'eager':
    default:
      return {
        loading: 'eager',
        src: params.src,
      };
  }
};

export default React.memo(ImageX);
